export const productSingleSlider = {
  margin: 0,
  nav: true,
  loop: false,
  dots: false,
  autoplay: false
};

export const prodThumbSlider = {
  items: 4,
  margin: 10,
  dots: false,
  nav: true,
  navText: ['<i class=icon-left-open-big>', '<i class=icon-right-open-big>']
};

export const productExtendSlider = {
  loop: true,
  margin: 0,
  nav: true,
  dots: false,
  autoplay: false,
  center: true,
  responsive: {
    0: {
      items: 1
    },
    480: {
      items: 2
    },
    1200: {
      items: 3
    }
  }
};

export const HomeSlider = {
  autoplay: true,
  nav: false,
  dots: true,
  autoplayTimeout: 6000,
  // animateOut: 'fadeOut', - conflicts with the 'react-awesome-reveal' and does not work correctly,
  loop: true,
  lazyLoad: false,
  autoplayHoverPause: true
};

export const HomeSliderMobile = {
  autoplay: true,
  nav: false,
  dots: false,
  autoplayTimeout: 6000,
  loop: true,
  lazyLoad: false,
  autoplayHoverPause: true
}

export const autoScroll = {
  autoplay: true,
  nav: false,
  dots: false,
  autoplayTimeout: 5000,
  loop: true,
  items: 4,
  autoplaySpeed: 5000,
  animateOut: 'linear',
  animateIn: 'linear',
  mouseDrag: false,
  touchDrag: false,
  pullDrag: false,
  responsive: {
    0: {
      items: 1.5,
    },
    480: {
      items: 2
    },
    768: {
      items: 2,
      center: true
    },
    991: {
      items: 2,
      center: true
    },
    1200: {
      items: 3
    },
    1400: {
      items: 4
    }
  }
};

export const blogSlider = {
  loop: false,
  margin: 20,
  autoplay: false,
  responsive: {
    0: {
      items: 1
    },
    480: {
      items: 2
    },
    768: {
      items: 3
    }
  }
};

export const InstaSlider = {
  loop: false,
  margin: 0,
  autoplay: false,
  dots: false,
  nav: false,
  responsive: {
    0: {
      items: 2
    },
    480: {
      items: 3
    },
    576: {
      items: 4
    },
    768: {
      items: 6
    },
    992: {
      items: 8
    },
    1200: {
      items: 10
    }
  }
};

export const postSlider = {
  margin: 0,
  dots: false,
  lazyLoad: true,
  nav: true,
  items: 1
};

export const productSlider = {
  loop: false,
  margin: 20,
  autoplay: false,
  dots: false,
  nav: true,
  items: 2,
  responsive: {
    576: {
      items: 3
    },
    992: {
      items: 4
    }
  }
};

export const categorySlider = {
  loop: false,
  margin: 20,
  autoplay: false,
  nav: true,
  dots: false,
  items: 2,
  responsive: {
    576: {
      items: 3
    },
    992: {
      items: 5
    }
  }
};

export const widgetFeaturedProductSlider = {
  margin: 20,
  loop: false,
  nav: true,
  navText: ['<i class="icon-angle-left">', '<i class="icon-angle-right">'],
  dots: false
};

export const infoBoxSlider = {
  loop: false,
  dots: false,
  responsive: {
    576: {
      items: 2
    },
    992: {
      items: 3
    }
  }
};

export const bannerSlider = {
  margin: 20,
  responsive: {
    576: {
      items: 2
    },
    992: {
      items: 3
    }
  }
};

export const brandSlider = {
  loop: false,
  margin: 40,
  autoHeight: true,
  autoplay: false,
  dots: false,
  nav: false,
  navText: [
    '<i class="icon-left-open-big">',
    '<i class="icon-right-open-big">'
  ],
  items: 2,
  responsive: {
    0: {
      items: 3,
      margin: 15
    },
    480: {
      items: 4,
      margin: 20
    },
    768: {
      items: 4,
      margin: 20
    },
    991: {
      items: 4,
      margin: 20
    },
    1200: {
      items: 5
    }
  }
};

export const reviewSlider = {
  loop: false,
  autoHeight: false,
  autoplay: false,
  dots: true,
  nav: false,
  responsive: {
    0: {
      items: 1,
      dots: false,
      nav: true
    },
    480: {
      items: 1,
      dots: false,
      nav: true
    },
    768: {
      items: 2,
      dots: false,
      nav: true
    },
    991: {
      items: 2,
      dots: false,
      nav: true
    },
    1200: {
      items: 2,
      dots: false,
      nav: true
    },
    1400: {
      items: 3
    }
  }
};
