import React, {useEffect} from 'react';
import {Provider, useStore} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {useRouter} from 'next/router';
import 'react-phone-input-2/lib/style.css';
import {wrapper} from '../store';
import Layout from '../components/layout';
import '../public/sass/style.scss';
import SecondaryLayout from '../components/SecondaryLayout';
import {useAuthModalStore} from '../store/auth-modal';
import {useAxios} from '../hooks/useAxios/useAxios';
import {ToastContainer} from 'react-toastify';
import {logRocketInit} from '../utils/logrocket';
import Head from 'next/head';
import Script from 'next/script';
import {handleBeforeUnload} from '../utils';
import versionConfig from '../environments/versionConfig';
import ErrorBoundary from '../components/ErrorBoundary';
import Error from './error'

logRocketInit();

const App = ({Component, pageProps}) => {
  const store = useStore();
  const router = useRouter();

  const {
    monitorUserSessionFromCookie,
    setUserData,
    ...state
  } = useAuthModalStore();

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
  }, []);

  useAxios(
    '/public/buyontrust/auth/current_customer?store=furniture',
    { method: 'post' },
    state.isUserLogged,
    data => {
      if (data) {
        localStorage.setItem('customerData', JSON.stringify(data));
        setUserData(data);
      }
    }
  );

  useEffect(() => {
    console.log(
      `App launched on environment:  = ${process.env.TARGET_ENVIRONMENT}, version ${versionConfig.version}`
    );

    // if (store.getState().demo.current !== 8) {ç
    //     store.dispatch(DemoAction.refreshStore(8));
    // }
  }, []);

  useEffect(() => {
    monitorUserSessionFromCookie();
  }, [state.isUserLogged, state.ddToken, state.jwtToken]);

  const CurrentLayout = router.pathname === '/' ? SecondaryLayout : Layout;
  return (
    <ErrorBoundary fallback={<Error />}>
      <Provider store={store}>
        <PersistGate
          // loading={
          //   <div className="loading-overlay">
          //     <div className="bounce-loader">
          //       <div className="bounce1" />
          //       <div className="bounce2" />
          //       <div className="bounce3" />
          //     </div>
          //   </div>
          // }
          persistor={store.__persistor}>
          {() => (
            <>
              <Head>
                <meta charSet="UTF-8"/>
                <meta
                  content="width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1"
                  name="viewport"
                />

                <title>Buy On Trust</title>

                <meta content="React Template" name="keywords" key="keywords"/>
                <meta content="Buy On Trust" name="description" key="desc"/>

                <meta property="og:title" content="Buy On Trust" key="og:title"/>
                <meta property="og:description" content="Your ideal furniture in a click" key="og:desc"/>
                <meta property="og:image" content={`${process.env.FURNITURE_URL}/images/banners/tom-unsplash.jpg`}
                      key="og:img"/>
                <meta property="og:image:width" content="1200"/>
                <meta property="og:image:height" content="630"/>
                <meta property="og:type" content="website" key="og:type"/>
                <meta property="og:site_name" content="Buy On Trust" key="og:site"/>

              </Head>

              {/* Google Tag Manager Script */}
              <Script
                async
                src="https://www.googletagmanager.com/gtag/js?id=G-PK103YB8SX"
              />
              <Script
                dangerouslySetInnerHTML={{
                  __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-PK103YB8SX');
            `
                }}
                id="gtag-init"
              />
              <CurrentLayout>
                <Component {...pageProps} />
              </CurrentLayout>
            </>
          )}
        </PersistGate>
        <ToastContainer autoClose={3000} duration={300}/>
      </Provider>
    </ErrorBoundary>
  );
};

// App.getInitialProps = async ({ Component, ctx }) => {
//     let pageProps = {};
//     if (Component.getInitialProps) {
//         pageProps = await Component.getInitialProps(ctx);
//     }
//     return { pageProps };
// };

export default wrapper.withRedux(App);
