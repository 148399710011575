import { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import 'react-18-image-lightbox/style.css';
import Cookie from 'js-cookie';
import 'react-toastify/dist/ReactToastify.min.css';
import 'rc-tree/assets/index.css';
import 'react-18-image-lightbox/style.css';

const Header = dynamic(() => import('./common/header'), { ssr: false });
const Footer = dynamic(() => import('./common/footer'), { ssr: false });
import MobileMenu from './common/partials/mobile-menu';
import QuickModal from '../components/features/modals/quickview';
import VideoModal from '../components/features/modals/video-modal';

import modal, { actions } from '../store/modal';
import { stickyInit, scrollTopHandlder, scrollTopInit } from '../utils';
// import { useLiveChat } from '../hooks/useLiveChat';
import { useRouter } from 'next/router';

import IframeModal from './features/modals/iframe-modal';
import HowItWorks from './features/modals/how-it-works';
import { HowItWorksStyles } from '../themes/iframe-modal';
import FullheightIframe from './features/modals/fullheight-iframe';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { getLocalStorage, setLocalStorage } from '../utils/storage/storage';
import { defaultQueryParamsForShop } from './constants';
import useApproveModalStore from '../store/approve-modal';
import { getCategories, getSubCategories } from '../store/categories';
import { constructInitMarketingData } from '../utils/utils';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import { useAuthModalStore } from '../store/auth-modal';

const uownSrc = process.env.NEXT_PUBLIC_UOWN_BASE_URL || '';

function Layout({ children, hideQuickView, hideVideo }) {
  const router = useRouter();
  const dispatch = useDispatch();

  const {
    cartItems,
    cart_amount,
    shipping_cost,
    discount_amount
  } = useSelector(state => state.cart);

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getSubCategories());
  }, []);

  const { isOpen, closeModal } = useApproveModalStore();
  const { currentCustomerData } = useAuthModalStore();

  const [showTopNotice, setShowTopNotice] = useState(
    !Cookie.get('closeTopNotice')
  );
  //* temp disabled because trial licence ended
  // useLiveChat();

  function closeTopNotice() {
    setShowTopNotice(false);
    Cookie.set('closeTopNotice', true, {
      expires: 7,
      path: router?.basePath
    });
  }

  useEffect(() => {
    window.addEventListener('scroll', stickyInit, { passive: true });
    window.addEventListener('scroll', scrollTopInit, { passive: true });
    window.addEventListener('resize', stickyInit);
    hideQuickView();
    hideVideo();

    return () => {
      window.removeEventListener('scroll', stickyInit, { passive: true });
      window.removeEventListener('scroll', scrollTopInit, {
        passive: true
      });
      window.removeEventListener('resize', stickyInit);
    };
  }, []);

  const [leaseProvider, setLeaseProvider] = useState('uown'); // also on the checkout page
  const [isShowLeaseModal, setIsShowLeaseModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isApproved, setIsApproved] = useState(false);

  const constructCurrentUownUrl = () => {
    if (typeof window === 'undefined')
      return `${uownSrc}/?store_type=furniture`;

    const customerPhone = JSON.parse(localStorage.getItem('customerData'))
      ?.customer?.phone;

    let analyticParamsQuery = constructInitMarketingData(
      'analytic_params',
      'furniture'
    );

    // const marketingData = JSON.parse(localStorage.getItem('marketingData'));
    // let marketingDataQuery = '';
    // if (marketingData?.pid)
    //   marketingDataQuery = marketingDataQuery.concat(
    //     `pid=${marketingData?.pid}`
    //   );
    // if (marketingData?.sid)
    //   marketingDataQuery = marketingDataQuery.concat(
    //     `&sid=${marketingData?.sid}`
    //   );
    // if (marketingData?.eid)
    //   marketingDataQuery = marketingDataQuery.concat(
    //     `&eid=${marketingData?.eid}`
    //   );
    // if (marketingData?.oid)
    //   marketingDataQuery = marketingDataQuery.concat(
    //     `&oid=${marketingData?.oid}`
    //   );
    // if (marketingData?.uid)
    //   marketingDataQuery = marketingDataQuery.concat(
    //     `&uid=${marketingData?.uid}`
    //   );
    // if (marketingData?.gclid)
    //   marketingDataQuery = marketingDataQuery.concat(
    //     `&gclid=${marketingData?.gclid}`
    //   );

    const lineItems = {
      discountAmount: discount_amount,
      merchandiseSubtotal: Number(cart_amount - shipping_cost),
      orderTotal: Number(cart_amount),
      deliveryCharge: Number(shipping_cost),
      items: cartItems.map(item => ({
        productId: item.numberId,
        sku: item.sku,
        name: item.name.replace('&', 'and'),
        brand: item.name.replace('&', 'and'),
        quantity: item.qty,
        salePrice: item.price[0] || 0, // ?.toFixed(2),
        extendedSalePrice: Number(item.qty * item.price[0])
      }))
    };
    if (Cookies.get('DD_TOKEN') && !!customerPhone) {
      const uownUrl = `${uownSrc}/buyontrust/${customerPhone}/items?data=${JSON.stringify(
        lineItems
      )}&${analyticParamsQuery}`;
      return uownUrl;
    }

    // console.log(`${uownSrc}/?${analyticParamsQuery}`);
    return `${uownSrc}/?${analyticParamsQuery}`;
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
    closeModal();
    setLocalStorage('showHowItWorks', !isChecked);
  };

  const handleCloseLeaseModal = () => {
    setIsShowLeaseModal(false);
    if (router.query.slug) {
      router.reload();
      return;
    }

    let newQuery = { ...router.query };

    let newQueryToString = '';
    if (new URLSearchParams(newQuery).toString().length) {
      newQueryToString = `?${new URLSearchParams(newQuery).toString()}`;
    } else {
      // Set default
      if (router.pathname === '/shop') {
        newQueryToString = defaultQueryParamsForShop; // '?search=&filters=%7B"categories"%3A%5B%5D%2C"price"%3A%7B"min"%3A0%2C"max"%3A0%7D%2C"materials"%3A%5B%5D%2C"colors"%3A%5B%5D%7D&page=1}';
      }
    }
    const newUrl = `${window.location.origin}${router.pathname}${newQueryToString}`;
    window.history.replaceState({}, '', newUrl);

    window.location.reload();
  };

  useEffect(() => {
    const customerData = localStorage.getItem('customerData');
    if (customerData) {
      const parsedData = JSON.parse(customerData);
      const leaseData = parsedData.lease_data || [];
      const approvedLease = leaseData.find(
        lease => lease?.name === 'uown_furniture' && lease?.application_status
      );
      if (approvedLease?.application_status === 'approved') {
        setIsApproved(true);
        setLocalStorage('showHowItWorks', false);
      }
    }
  }, [currentCustomerData]);

  useEffect(() => {
    if (!isApproved) {
      const timer = setTimeout(() => {
        if (router.pathname === '/' || router.pathname === '/home') {
          getLocalStorage('showHowItWorks') !== false && setIsOpenModal(true);
        }
      }, 7000);

      return () => clearTimeout(timer);
    }
  }, [isApproved, router.pathname]);

  useEffect(() => {
    if (isOpen) setIsOpenModal(true);
  }, [isOpen]);

  return (
    <>
      <div className="page-wrapper" id={'root'}>
        {/*{showTopNotice ? (*/}
        {/*  <div className="pre-header overflow-hidden">*/}
        {/*    <div>*/}
        {/*      <div className="container">*/}
        {/*        Get Up to <b>40% OFF</b> New Furniture Deals{' '}*/}
        {/*        <small>* Limited time only.</small>*/}
        {/*      </div>*/}
        {/*      <button*/}
        {/*        title="Close (Esc)"*/}
        {/*        type="button"*/}
        {/*        onClick={closeTopNotice}*/}
        {/*        className="mfp-close">*/}
        {/*        ×*/}
        {/*      </button>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*) : (*/}
        {/*  ''*/}
        {/*)}*/}

        <Header />

        {children}

        <Footer />
        {/*<SecondaryFooter />*/}
        <QuickModal />
        <VideoModal />

        <div className="wishlist-popup">
          <div className="wishlist-popup-msg">Product added!</div>
        </div>
      </div>

      <MobileMenu />
      {/*      <StickyNavbar />*/}

      <a
        className="d-flex justify-content-center align-items-center"
        href="#"
        id="scroll-top"
        onClick={scrollTopHandlder}
        role="button"
        title="Top">
        <Image src={'/images/arrow-up-icon.svg'} width={20} height={20} />
      </a>
      <IframeModal
        isOpen={isOpenModal}
        onRequestClose={handleCloseModal}
        styles={HowItWorksStyles}>
        <HowItWorks
          handleClose={handleCloseModal}
          isChecked={isChecked}
          setIsChecked={setIsChecked}
          setIsOpenModal={setIsOpenModal}
          setIsShowLeaseModal={setIsShowLeaseModal}
        />
      </IframeModal>
      {leaseProvider === 'uown' && (
        <IframeModal
          isOpen={isShowLeaseModal}
          onRequestClose={handleCloseLeaseModal}>
          <FullheightIframe src={constructCurrentUownUrl()} />
        </IframeModal>
      )}
    </>
  );
}

export default connect(null, actions)(Layout);
