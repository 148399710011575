import versionConfig from '../../environments/versionConfig';
import React, { useEffect, useState } from 'react';
import ALink from './ALink';
import { useRouter } from 'next/router';
import Image from 'next/image';
import cn from 'classnames';

export default function SecondaryHeader() {
  const [isBtnVisible, setIsBtnVisible] = useState(false);
  const [isLogoVisible, setIsLogoVisible] = useState(true);

  const router = useRouter();
  const { query } = router;

  const addHeaderClass = () => {
    let scrollYVal = window.scrollY;

    if (scrollYVal > 520) {
      setIsBtnVisible(true);
      setIsLogoVisible(false);
    }
    if (scrollYVal < 520) {
      setIsBtnVisible(false);
      setIsLogoVisible(true);
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', addHeaderClass);

    return () => {
      document.removeEventListener('scroll', addHeaderClass);
    };
  }, []);

  const nodeEnv = process.env.NODE_ENV;
  console.log('Env =', nodeEnv);

  return (
    <>
      <header className="header header-middle header-fixed" id="landHead">
        <div className="container">
          <div className={cn('header-left', { visible: isLogoVisible })}>
            <ALink className="logo" href="/">
              <img alt="BuyOnTrust Logo" src="/images/home/logo_red.png" />
              <div className={'version'}>
                {nodeEnv !== 'production' ? `v ${versionConfig.version}` : ''}
              </div>
            </ALink>
          </div>
          <div
            className={cn('btns-header-container', { visible: isBtnVisible })}>
            <ALink
              className="btn btn-dark btn-header mr-3"
              href={`${process.env.FURNITURE_URL}/home?${new URLSearchParams(query).toString()}`}
            >
              Shop Furniture
            </ALink>
            <ALink
              className="btn btn-dark btn-header"
              href={`${process.env.ELECTRONICS_URL}?${new URLSearchParams(query).toString()}`}
            >
              Shop Electronics
            </ALink>
          </div>
          <div className="header-right d-lg-block">
            <div className="header-contact d-flex justify-content-end align-items-center pl-1 mr-lg-5 pr-xl-2">
              <Image
                src={'/images/landing-phone-icon.svg'}
                width={24}
                height={24}
                style={{ marginTop: '0.3rem' }}
              />
              <h6>
                <a className="font4" href="tel:+18882747732">
                  (888) 274-7732
                </a>
              </h6>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
